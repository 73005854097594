import { Instruction } from './instruction.model';

export interface Section {
  heading: string;
  headingValid: boolean;
  headingLevel: number;
  introductionText: string;
  introductionTextValid: boolean;
  conditionExpression: string;
  conditionValid: boolean;
  pageBreak: boolean;
  instructions: Instruction[];
}

export function getEmptySection(): Section {
  return {
    heading: '',
    headingLevel: 0,
    headingValid: true,
    introductionText: '',
    introductionTextValid: true,
    conditionExpression: '',
    conditionValid: true,
    pageBreak: false,
    instructions: [],
  };
}
