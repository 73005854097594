<button class="cx-icon-stroked-button placeholder-edit-button" mat-stroked-button (click)="openDialog()"
        (keydown.space)="openDialog()">
  <mat-icon class="material-symbols-outlined">data_object</mat-icon>
</button>

<ng-template #placeholderEdit>
  <div class="placeholder-edit-window">
    <div class="conditions">

      <form [formGroup]="form">

        <div class="placeholder-line row gap">
          <mat-form-field>
            <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.AREA' | translate }}</mat-label>
            <mat-select formControlName="area">
              <mat-option *ngFor="let area of getAreas()" [value]="area">{{ area }}</mat-option>
            </mat-select>
            <mat-error>
              @if (form.controls['area'].hasError('invalid')) {
                {{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.ERROR_INVALID' | translate }}
              } @else if (form.controls['area'].hasError('empty')) {
                {{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.ERROR_EMPTY' | translate }}
              }
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.QUESTION' | translate }}</mat-label>
            <mat-select formControlName="element">
              <mat-option *ngFor="let elementId of getElements()" [value]="elementId">{{ elementId }}
              </mat-option>
            </mat-select>
            <mat-error>
              @if (form.controls['element'].hasError('invalid')) {
                {{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.ERROR_INVALID' | translate }}
              } @else if (form.controls['element'].hasError('empty')) {
                {{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.ERROR_EMPTY' | translate }}
              }
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.VALUE' | translate }}</mat-label>
            <mat-select formControlName="field">
              <mat-option *ngFor="let field of getFields()" [value]="field">{{ field }}</mat-option>
            </mat-select>
            <mat-error>
              @if (form.controls['field'].hasError('invalid')) {
                {{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.ERROR_INVALID' | translate }}
              } @else if (form.controls['field'].hasError('empty')) {
                {{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.ERROR_EMPTY' | translate }}
              }
            </mat-error>
          </mat-form-field>

        </div>
      </form>
    </div>

    <div class="spacer"></div>
    <div class="format-line row">
      <mat-checkbox [(ngModel)]="hasFormat"
                    (change)="updatePreview()">{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.FORMAT' | translate }}
      </mat-checkbox>
      <mat-icon [matTooltip]="'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.FORMAT_HINT' | translate">info</mat-icon>
      @if (hasFormat) {
        <mat-form-field>
          <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.PREFIX' | translate }}</mat-label>
          <input [(ngModel)]="prefix" (ngModelChange)="updatePreview()" type="text" matInput />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.SUFFIX' | translate }}</mat-label>
          <input [(ngModel)]="suffix" (ngModelChange)="updatePreview()" type="text" matInput />
        </mat-form-field>
      }
    </div>

    <div class="dialog-buttons row">
      <mat-form-field class="col-8">
      <textarea cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="12" (blur)="setPlaceholder()"
                [(ngModel)]="placeholderPreview" matInput></textarea>
      </mat-form-field>
      <div class="spacer"></div>
      <button mat-stroked-button
              (click)="onClose(false)">{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.CANCEL' | translate }}
      </button>
      <button mat-flat-button color="accent"
              (click)="onClose(true)">{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.PLACEHOLDER_EDIT.CONFIRM' | translate }}
      </button>
    </div>

  </div>
</ng-template>
