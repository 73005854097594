import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateOverviewComponent } from './template-overview/template-overview.component';
import { TemplateDetailComponent } from './template-detail/template-detail.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: TemplateOverviewComponent },
  { path: ':id', component: TemplateDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TemplateRoutingModule {}
