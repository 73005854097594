import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'hpm-template-overview',
  templateUrl: './template-overview.component.html',
  styleUrl: './template-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateOverviewComponent {
  readonly ORDER_TAB_INDEX = 0;
  readonly TEMPLATE_TAB_INDEX = 1;

  constructor(private router: Router) {}

  navigateOnTab(event: MatTabChangeEvent): void {
    if (event.index === this.ORDER_TAB_INDEX) {
      this.router.navigateByUrl('/order');
    } else if (event.index === this.TEMPLATE_TAB_INDEX) {
      this.router.navigateByUrl('/template');
    }
  }

}
