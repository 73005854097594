<button class="cx-icon-stroked-button condition-edit-button" mat-stroked-button (click)="openDialog()" (keydown.space)="openDialog()">
    <mat-icon class="material-symbols-outlined">edit</mat-icon>
</button>

<ng-template #conditionEdit>
  <div class="condition-edit-window">
    <div class="conditions">

      @for (condition of conditions; track condition) {
        <div [style.padding-left]="(condition.indentation * 4) + 'rem'">

          <div class="condition-line row gap">
            <mat-form-field>
              <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.AREA' | translate }}</mat-label>
              <mat-select [(value)]="condition.area" (valueChange)="areaSelectionChanged(condition); updatePreview()">
                <mat-option *ngFor="let area of getAreas()" [value]="area">{{ area }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.QUESTION' | translate }}</mat-label>
              <mat-select [disabled]="isElementDisabled(condition)" [(value)]="condition.elementId"
                          (valueChange)="elementSelectionChange(condition); updatePreview()">
                <mat-option *ngFor="let elementId of getElements(condition)" [value]="elementId">{{ elementId }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.VALUE' | translate }}</mat-label>
              <mat-select [disabled]="isValueDisabled(condition)" [(value)]="condition.value" (valueChange)="updatePreview()">
                <mat-option *ngFor="let value of getValues(condition)" [value]="value">{{ value }}</mat-option>
              </mat-select>
            </mat-form-field>

              <button class="cx-icon-stroked-button" mat-stroked-button [disabled]="condition.indentation > 2"
                      (click)="condition.indentation = increaseIndent(condition.indentation); updatePreview()">
                <mat-icon>format_indent_increase</mat-icon>
              </button>
              <button class="cx-icon-stroked-button" mat-stroked-button [disabled]="condition.indentation === 0"
                      (click)="condition.indentation = decreaseIndent(condition.indentation); updatePreview()">
                <mat-icon>format_indent_decrease</mat-icon>
              </button>
          </div>
        </div>

          <div class="button-line row gap" [style.padding-left]="(condition.operatorIndentation * 4) + 'rem'">
            <button class="cx-icon-stroked-button" mat-stroked-button [class.selected]="condition.operator === 'AND'"
                    (click)="addLine('AND', condition, $index)" (keydown.space)="addLine('AND', condition, $index)">AND
            </button>
            <button class="cx-icon-stroked-button" mat-stroked-button [class.selected]="condition.operator === 'OR'"
                    (click)="addLine('OR', condition, $index)" (keydown.space)="addLine('OR', condition, $index)">OR
            </button>
            <button class="cx-icon-stroked-button" mat-stroked-button [disabled]="condition.operatorIndentation > 2"
                    (click)="condition.operatorIndentation = increaseIndent(condition.operatorIndentation); updatePreview()">
              <mat-icon>format_indent_increase</mat-icon>
            </button>
            <button class="cx-icon-stroked-button" mat-stroked-button [disabled]="condition.operatorIndentation === 0"
                    (click)="condition.operatorIndentation = decreaseIndent(condition.operatorIndentation); updatePreview()">
              <mat-icon>format_indent_decrease</mat-icon>
            </button>
            <button class="cx-icon-stroked-button" mat-stroked-button (click)="deleteCondition($index)"
                    (keydown.space)="deleteCondition($index)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
      }
    </div>

    <div class="dialog-buttons row">
      <mat-form-field class="col-8">
      <textarea cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="12" (blur)="setConditions()"
                [(ngModel)]="conditionPreview" matInput></textarea>
      </mat-form-field>
      <div class="spacer"></div>
      <button mat-stroked-button
              (click)="onClose(false)">{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.CANCEL' | translate }}
      </button>
      <button mat-flat-button color="accent"
              (click)="onClose(true)">{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.CONFIRM' | translate }}
      </button>
    </div>

  </div>
</ng-template>
