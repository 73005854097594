import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { ConditionSelection } from './condition.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConditionHttpService {
  private readonly baseUrl = `${environment.baseUrl}/questionnaire/area`;

  private cachedSelections: ConditionSelection[] | null = null;

  constructor(private httpClient: HttpClient) {}

  getSelectOptions(): Observable<ConditionSelection[]> {
    if (this.cachedSelections) {
      return of(this.cachedSelections);
    } else {
      return this.httpClient
        .get<ConditionSelection[]>(`${this.baseUrl}/metamodel`)
        .pipe(
          tap((data) => {
            this.cachedSelections = this.sortSuggestions(data);
          }),
        );
    }
  }

  private sortSuggestions(
    suggestions: ConditionSelection[],
  ): ConditionSelection[] {
    suggestions.sort((a, b) => a.name.localeCompare(b.name));
    suggestions.forEach((selection) => {
      selection.elements.sort((a, b) => a.elementId.localeCompare(b.elementId));
      selection.elements.forEach((element) => {
        element.fields.sort((a, b) => a.name.localeCompare(b.name));
      });
    });
    return suggestions;
  }
}
