import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Instruction } from '../../../../model/instruction.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { TemplateHttpService } from '../../../../template-overview/template-http.service';

type InstructionChange = {
  text: string;
  index: number;
  field: string;
};

@Component({
  selector: 'hpm-instruction-table-edit',
  templateUrl: './instruction-table-edit.component.html',
  styleUrl: './instruction-table-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionTableEditComponent implements OnInit {
  @Input() instructions!: Instruction[];
  @Output() instructionsChange: EventEmitter<Instruction[]> = new EventEmitter<
    Instruction[]
  >();
  private readonly OPEN_RED_SPAN = '<span style="color:red;">';
  private readonly CLOSING_SPAN = '</span>';

  constructor(
    private templateHttpService: TemplateHttpService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.ensureInstructionsNotEmpty();
  }

  drop(event: CdkDragDrop<string[]>): void {
    const movedElement = this.instructions.splice(event.previousIndex, 1)[0];
    this.instructions.splice(event.currentIndex, 0, movedElement);
  }

  addInstruction(index: number): void {
    const emptyInstruction: Instruction = {
      icon: '',
      what: '',
      when: '',
      how: '',
      where: '',
      dosage: '',
      who: '',
      colorCode: this.instructions[index]?.colorCode,
    };
    this.instructions.splice(index + 1, 0, emptyInstruction);
    this.instructionsChange.emit(this.instructions);
  }

  deleteInstruction(index: number): void {
    this.instructions.splice(index, 1);
    this.ensureInstructionsNotEmpty();
  }

  private ensureInstructionsNotEmpty(): void {
    if (!this.instructions || this.instructions.length === 0) {
      this.instructions = [];
      this.addInstruction(0);
    }
    this.instructionsChange.emit(this.instructions);
  }

  validateText(change: InstructionChange): void {
    if (!change.text) {
      return;
    }
    this.templateHttpService
      .isExpressionValid(change.text, false)
      .subscribe((textValid) => {
        if (textValid) {
          this.removeInvalidMark(change);
        } else {
          this.addInvalidMark(change);
        }
        this.cdr.detectChanges();
      });
  }

  private removeInvalidMark(change: InstructionChange): void {
    const instruction = this.instructions[change.index];
    let newText = change.text;
    while (newText.includes(this.OPEN_RED_SPAN)) {
      newText = newText.replace(this.OPEN_RED_SPAN, '');
      const lastSpanIndex = newText.lastIndexOf(this.CLOSING_SPAN);
      newText =
        newText.substring(0, lastSpanIndex) +
        newText.substring(lastSpanIndex + this.CLOSING_SPAN.length);
    }
    /* eslint-disable */ // use any to access field via string reference
    (instruction as any)[change.field] = newText;
    /* eslint-enable */
  }

  private addInvalidMark(change: InstructionChange): void {
    if (!change.text.includes(this.OPEN_RED_SPAN)) {
      const instruction = this.instructions[change.index];
      /* eslint-disable */ // use any to access field via string reference
      (instruction as any)[change.field] =
        this.OPEN_RED_SPAN + change.text + this.CLOSING_SPAN;
      /* eslint-enable */
    }
  }
}
