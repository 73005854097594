<button class="cx-icon-stroked-button" mat-stroked-button [disabled]="disabled"
        (click)="openUploadDialog()">
  <mat-icon class="material-symbols-outlined">upload</mat-icon>
</button>

<ng-template #uploadDialog>
  <cx-file-uploader
    [heading]=" 'TEMPLATE_OVERVIEW.TABLE.UPLOAD.HEADLINE' | translate"
    [description]=" 'TEMPLATE_OVERVIEW.TABLE.UPLOAD.DESCRIPTION' | translate: {name: template.name}"
    [buttonText]=" 'TEMPLATE_OVERVIEW.TABLE.UPLOAD.BUTTON' | translate"
    [errorMessage]=" 'TEMPLATE_OVERVIEW.TABLE.UPLOAD.ERROR_MESSAGE' | translate"
    [acceptedFileTypes]="'application/json'"
    [manualUpload]="true"
    [files]="files"
    (selectFile)="fileSelected($event)"
    (removeFile)="fileRemoved($event)"
  ></cx-file-uploader>
</ng-template>
