import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TemplateHttpService } from '../template-http.service';
import { catchError, Subject, take, takeUntil } from 'rxjs';
import { PageRequest } from '../../../shared/model/page-request.model';
import { TemplateType } from '../../model/template.model';
import { PageEvent } from '@angular/material/paginator';
import { MatSortable } from '@angular/material/sort';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TemplateView } from '../../model/template-view.model';
import {
  AuthorizedUser,
  hasAnyOfThoseRoles,
  UserRole,
} from '../../../shared/model/user.model';
import { UserHttpService } from '../../../shared/service/user-http.service';
import { CxTableLabelTypeEnum } from '@bbraun/cortex/table-utilities';

export enum TemplateColumnDef {
  NAME = 'name',
  ACTIONS = 'actions',
}

@Component({
  selector: 'hpm-template-table',
  templateUrl: './template-table.component.html',
  styleUrl: './template-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateTableComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();
  protected readonly templateColumnDef = TemplateColumnDef;
  private currentUser: AuthorizedUser | null = null;

  shownData: TemplateView[] = [];
  totalElementCount = 0;
  tableLoading = false;
  tableColumnsOrder = [TemplateColumnDef.NAME, TemplateColumnDef.ACTIONS];
  tableSettings: {
    pageSize: number;
    pageNumber: number;
    sort: { id: string; start: 'asc' | 'desc' | '' };
    filter: Map<string, string[]>;
  } = {
    pageSize: 10,
    pageNumber: 0,
    sort: { id: 'name', start: 'asc' },
    filter: new Map<string, string[]>(),
  };
  autocompleteOptions: string[] = [];
  templateType = TemplateType;

  constructor(
    private templateHttpService: TemplateHttpService,
    private router: Router,
    private userService: UserHttpService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadTemplateData();
    this.loadAutocompleteOptions();
    this.loadUser();
  }

  private loadUser(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        this.currentUser = user;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  loadTemplateData(): void {
    this.tableLoading = true;
    const pageRequest = new PageRequest({
      page: this.tableSettings.pageNumber,
      size: this.tableSettings.pageSize,
      filter: this.tableSettings.filter,
    });
    if (this.tableSettings.sort && this.tableSettings.sort.start !== '') {
      pageRequest.sort = this.tableSettings.sort;
    }
    this.templateHttpService
      .getAll(pageRequest)
      .pipe(
        takeUntil(this.onDestroy$),
        catchError((err: HttpErrorResponse) => {
          this.tableLoading = false;
          throw err;
        }),
      )
      .subscribe((templatePageResult) => {
        this.shownData = templatePageResult.content;
        this.totalElementCount = templatePageResult.totalElements;
        this.tableLoading = false;
        this.cdr.detectChanges();
      });
  }

  onSortingDirectionChanged(sortData: MatSortable): void {
    this.tableSettings.pageNumber = 0;
    this.tableSettings.sort = {
      start: sortData.start,
      id: sortData.id,
    };
    this.loadTemplateData();
  }

  onPaginatorChange(changeData: PageEvent): void {
    this.tableSettings.pageNumber = changeData.pageIndex;
    this.tableSettings.pageSize = changeData.pageSize;
    this.loadTemplateData();
  }

  addSearchFilter(searchvalue: string[]): void {
    this.tableSettings.filter.set('search.fields', ['name']);
    this.tableSettings.filter.set('search.terms', searchvalue);
    this.loadTemplateData();
  }

  private loadAutocompleteOptions(): void {
    this.templateHttpService
      .getNames()
      .pipe(take(1))
      .subscribe((templateNames) => {
        this.autocompleteOptions = templateNames;
        this.cdr.detectChanges();
      });
  }

  openEditor(rowIndex: number): void {
    const templateId = this.shownData[rowIndex].id;
    this.router.navigateByUrl(`/template/${templateId}`);
  }

  getDownloadUrl(id: string): string {
    return this.templateHttpService.getDownloadUrl(id);
  }

  copyTemplate(id: string): void {
    this.tableLoading = true;
    this.templateHttpService
      .copyTemplate(id)
      .pipe(
        takeUntil(this.onDestroy$),
        catchError((err) => {
          this.tableLoading = false;
          throw err;
        }),
      )
      .subscribe(() => {
        this.loadTemplateData();
        this.tableLoading = false;
      });
  }

  isPrivilegedUser(): boolean {
    return hasAnyOfThoseRoles(this.currentUser, [
      UserRole.ADMIN,
      UserRole.OFFICE_SERVICE,
    ]);
  }

  isUserAdmin(): boolean {
    return hasAnyOfThoseRoles(this.currentUser, [UserRole.ADMIN]);
  }

  deleteTemplate(confirmation: boolean, templateId: string): void {
    if (confirmation) {
      this.templateHttpService
        .deleteTemplate(templateId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.loadTemplateData();
        });
    }
  }

  protected readonly tableLabelType = CxTableLabelTypeEnum;
}
