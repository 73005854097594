import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateOverviewComponent } from './template-overview/template-overview.component';
import { TranslateModule } from '@ngx-translate/core';
import { UIModule } from '../ui.module';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TemplateRoutingModule } from './template-routing.module';
import { TemplateTableComponent } from './template-overview/template-table/template-table.component';
import { UtilModule } from '../shared/util/util.module';
import { TemplateEditorComponent } from './template-detail/template-editor/template-editor.component';
import { TemplateDetailComponent } from './template-detail/template-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SectionEditComponent } from './template-detail/template-editor/section-edit/section-edit.component';
import { InstructionTableComponent } from './template-detail/template-editor/instruction-table/instruction-table.component';
import { InstructionTableEditComponent } from './template-detail/template-editor/section-edit/instruction-table-edit/instruction-table-edit.component';
import { IconSelectComponent } from './template-detail/template-editor/section-edit/icon-select/icon-select.component';
import { InstructionFieldEditComponent } from './template-detail/template-editor/section-edit/instruction-table-edit/instruction-field-edit/instruction-field-edit.component';
import { NgxEditorModule } from 'ngx-editor';
import { SharedModule } from '../shared/shared.module';
import { TemplateUploadDialogComponent } from './template-overview/template-table/template-upload-dialog/template-upload-dialog.component';
import { ConditionEditComponent } from './template-detail/template-editor/section-edit/condition-edit/codition-editor/condition-edit.component';
import { PlaceholderEditDirective } from './template-detail/template-editor/section-edit/placeholder-edit.directive';
import { PlaceholderEditComponent } from './template-detail/template-editor/section-edit/condition-edit/placeholder-edit/placeholder-edit.component';

@NgModule({
  declarations: [
    TemplateOverviewComponent,
    TemplateTableComponent,
    TemplateEditorComponent,
    TemplateDetailComponent,
    SectionEditComponent,
    InstructionTableComponent,
    InstructionTableEditComponent,
    IconSelectComponent,
    InstructionFieldEditComponent,
    TemplateUploadDialogComponent,
    ConditionEditComponent,
    PlaceholderEditDirective,
    PlaceholderEditComponent,
  ],
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    TemplateRoutingModule,
    TranslateModule,
    UIModule,
    UtilModule,
    FormsModule,
    NgxEditorModule,
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class TemplateModule {}
