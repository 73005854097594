<div class="section-edit">
<form [formGroup]="form">

<!--  condition -->
  <div class="row">
    <div class="label col-2">
      <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.CONDITION' | translate }}</mat-label>
    </div>
    <div  class="col-9">
      <hpm-two-label-toggle (changeToggleButton)="changedConditionExpressionToggle($event.checked)"
                            [checked]="conditionExpressionToggle"></hpm-two-label-toggle>
      <div *ngIf="conditionExpressionToggle" class="row gap">
        <mat-form-field class="col-11">
          <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.CONDITION' |translate }}</mat-label>
          <textarea formControlName="condition" cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="12" matInput></textarea>
          <mat-error>{{'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.CONDITION_ERROR' | translate}}</mat-error>
        </mat-form-field>
        <hpm-condition-edit class="condition-edit col-1" [condition]="form.controls['condition'].value" (conditionChange)="form.controls['condition'].patchValue($event)"></hpm-condition-edit>
      </div>
    </div>
  </div>

<!--  header-->
  <div class="row">
    <div class="label col-2">
      <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.HEADER' | translate }}</mat-label>
    </div>
    <div class="col-9">
      <hpm-two-label-toggle (changeToggleButton)="changedHeadingToggle($event.checked)"
                            [checked]="headingToggle"></hpm-two-label-toggle>

      <div class="row gap heading">
        <mat-form-field class="col-3" *ngIf="headingToggle">
          <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.HEADER_LEVEL' | translate }}</mat-label>
          <mat-select formControlName="headingLevel">
            <mat-option *ngFor="let i of [1,2,3,4]" [value]="i">
              {{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.HEADER' | translate }} {{i}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-9" *ngIf="headingToggle">
          <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.HEADER' | translate }}</mat-label>
          <textarea hpmPlaceholderEdit matInput formControlName="heading"
                    cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
          ></textarea>
          <mat-error>{{'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.PLACEHOLDER_ERROR' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

<!--  description-->
  <div class="row">
    <div class="label col-2">
      <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.INTRODUCTION_TEXT' | translate }}</mat-label>
    </div>
    <div class="col-9">
      <hpm-two-label-toggle (changeToggleButton)="changedIntroductionTextToggle($event.checked)"
                            [checked]="introductionTextToggle"></hpm-two-label-toggle>
      <mat-form-field *ngIf="introductionTextToggle">
        <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.INTRODUCTION_TEXT' | translate }}</mat-label>
        <textarea hpmPlaceholderEdit formControlName="introductionText" cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="12"
                  matInput></textarea>
        <mat-error>{{'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.PLACEHOLDER_ERROR' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </div>

<!--table -->
  <div class="table">
    <div class="row">
      <div class="label col-2">
        <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.TABLE' | translate }}</mat-label>
      </div>
      <div class="col-6">
        <hpm-two-label-toggle (changeToggleButton)="changedInstructionsToggle($event.checked)"
                              [checked]="instructionsToggle"></hpm-two-label-toggle>
      </div>
    </div>
    <hpm-instruction-table-edit *ngIf="instructionsToggle" [(instructions)]="section.instructions"></hpm-instruction-table-edit>
  </div>

<!--page break-->
  <div class="row">
    <div class="label col-2">
      <mat-label>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.PAGE_BREAK' | translate }}</mat-label>
    </div>
      <hpm-two-label-toggle (changeToggleButton)="section.pageBreak = $event.checked"
                            [checked]="section.pageBreak"></hpm-two-label-toggle>
  </div>

</form>
</div>
