import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { IconHttpService } from '../../../../../shared/service/icon-http.service';
import {
  CxDialogComponent,
  CxDialogConfig,
  CxDialogService,
} from '@bbraun/cortex/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { forkJoin, map, Observable, Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hpm-icon-select',
  templateUrl: './icon-select.component.html',
  styleUrl: './icon-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconSelectComponent implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  @Input() icon: string | undefined;
  @Output() iconChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() editMode = false;
  dialogRef: MatDialogRef<CxDialogComponent> | undefined;
  allIcons: string[] = [];
  selectedIcon: string | undefined;

  @ViewChild('iconDialog') templateRef: TemplateRef<never> | undefined;

  constructor(
    private iconService: IconHttpService,
    private dialogService: CxDialogService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getIconPath(iconName: string): string {
    return this.iconService.getIconUrl(iconName);
  }

  open(icon: string | undefined): void {
    if (!this.dialogRef) {
      this.selectedIcon = icon;
      this.loadAllIcons();

      this.getDialogConfig().subscribe((config) => {
        this.dialogRef = this.dialogService.openDialog(config);

        this.dialogRef.afterClosed().subscribe((closedWithSuccess) => {
          if (closedWithSuccess) {
            this.icon = this.selectedIcon;
            this.iconChange.emit(this.icon);
          }
          this.dialogRef = undefined;
          this.cdr.detectChanges();
        });
      });
    }
  }

  private getDialogConfig(): Observable<CxDialogConfig> {
    return forkJoin({
      title: this.translateService.get(
        'TEMPLATE_EDITOR.EDITOR_TABLE.ICON_SELECT.HEADLINE',
      ),
      cancel: this.translateService.get(
        'TEMPLATE_EDITOR.EDITOR_TABLE.ICON_SELECT.CANCEL',
      ),
      confirm: this.translateService.get(
        'TEMPLATE_EDITOR.EDITOR_TABLE.ICON_SELECT.CONFIRM',
      ),
    }).pipe(
      map(({ title, cancel, confirm }) => {
        return {
          title: title,
          confirmButtons: [{ text: confirm, value: true }],
          cancelButtons: [{ text: cancel, value: false }],
          template: this.templateRef,
        } as CxDialogConfig;
      }),
    );
  }

  loadAllIcons(): void {
    this.iconService
      .loadAllIcons()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((iconUrls) => {
        this.allIcons = iconUrls;
        this.cdr.detectChanges();
      });
  }

  getIconName(iconPath: string): string {
    const pathParts = iconPath.split('/');
    const filename = pathParts[pathParts.length - 1];
    return filename.split('.')[0];
  }

  selectIcon(icon: string | undefined): void {
    this.selectedIcon = icon;
  }

  closeDialog(success: boolean): void {
    if (this.dialogRef) {
      this.dialogRef.close(success);
    }
  }

  isIconSelected(icon: string | undefined): boolean {
    if (icon === this.selectedIcon) {
      return true;
    } else if (icon && this.selectedIcon) {
      return icon.includes(this.selectedIcon);
    }
    return false;
  }
}
