export const templateColors: string[] = [
  '',
  '#AADEFF',
  '#D5DF7E',
  '#DBDBDB',
  '#DFDBD3',
  '#EE767D',
  '#F9B65A',
  '#F0EDE6',
  '#FFE37D',
];

export const fontSizes = [
  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24,
];
