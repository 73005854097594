import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import {
  CxDialogComponent,
  CxDialogConfig,
  CxDialogService,
} from '@bbraun/cortex/dialog';
import { ConditionSelection } from '../condition.model';
import { ConditionHttpService } from '../condition-http.service';
import { TranslateService } from '@ngx-translate/core';
import { PlaceholderService } from './placeholder.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export type PlaceholderObject = {
  area?: string;
  element?: string;
  field?: string;
  hasFormat?: boolean;
  prefix?: string;
  suffix?: string;
};

@Component({
  selector: 'hpm-placeholder-edit',
  templateUrl: './placeholder-edit.component.html',
  // imported global by style.scss
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholderEditComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();

  @ViewChild('placeholderEdit') templateRef: TemplateRef<never> | undefined;
  @Input() placeholderString = '';
  placeholderPreview = '';
  @Output() placeholderChange: EventEmitter<string> =
    new EventEmitter<string>();
  dialogRef: MatDialogRef<CxDialogComponent> | undefined;
  form: FormGroup;
  suggestions: ConditionSelection[] = [];
  hasFormat = false;
  prefix = '';
  suffix = '';

  constructor(
    private conditionHttpService: ConditionHttpService,
    private placeholderService: PlaceholderService,
    private dialogService: CxDialogService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      area: ['', [this.validator(this.getAreas.bind(this))]],
      element: ['', [this.validator(this.getElements.bind(this))]],
      field: ['', [this.validator(this.getFields.bind(this))]],
    });
  }

  get area(): string {
    return this.form.controls['area'].value;
  }
  get element(): string {
    return this.form.controls['element'].value;
  }
  get field(): string {
    return this.form.controls['field'].value;
  }

  ngOnInit(): void {
    this.getSelectOptions();
    this.form.valueChanges.subscribe((value) => {
      this.form.patchValue(value, { emitEvent: false });
      this.areaSelectionChanged();
      this.elementSelectionChange();
      this.setElementEnableState();
      this.setFieldEnableState();
      this.updatePreview();
    });
  }

  updatePreview(): void {
    const formValue: PlaceholderObject = {
      area: this.area,
      element: this.element,
      field: this.field,
      hasFormat: this.hasFormat,
      prefix: this.prefix,
      suffix: this.suffix,
    };
    this.placeholderPreview =
      this.placeholderService.convertToPlaceholderString(formValue);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getSelectOptions(): void {
    this.conditionHttpService
      .getSelectOptions()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((suggestions) => {
        this.suggestions = suggestions;
      });
  }

  async openDialog(): Promise<void> {
    if (!this.dialogRef) {
      this.placeholderPreview = this.placeholderString;
      this.setPlaceholder();
      this.dialogRef = this.dialogService.openDialog(
        await this.getUpdateDialogConfig(),
      );
      this.dialogRef.updateSize('1200px', '500px');
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = undefined;
        this.form.reset();
        this.cdr.detectChanges();
      });
    }
  }

  private async getUpdateDialogConfig(): Promise<CxDialogConfig> {
    const title = await firstValueFrom(
      this.translateService.get(
        `TEMPLATE_EDITOR.EDITOR_TABLE.PLACEHOLDER_EDIT.HEADLINE`,
      ),
    );

    return {
      title: title,
      template: this.templateRef,
    } as CxDialogConfig;
  }

  setPlaceholder(): void {
    if (this.placeholderPreview !== '') {
      this.form.markAllAsTouched();
    }
    const placeholderObject = this.placeholderService.parsePlaceholder(
      this.placeholderPreview,
    );
    this.hasFormat = placeholderObject.hasFormat ?? false;
    this.prefix = placeholderObject.prefix ?? '';
    this.suffix = placeholderObject.suffix ?? '';
    this.form.setValue(
      {
        area: placeholderObject.area ? placeholderObject.area : '',
        element: placeholderObject.element ? placeholderObject.element : '',
        field: placeholderObject.field ? placeholderObject.field : '',
      },
      { emitEvent: false },
    );
    this.setElementEnableState();
    this.setFieldEnableState();
  }

  onClose(confirm: boolean): void {
    if (confirm) {
      this.placeholderString = this.placeholderPreview;
      this.placeholderChange.emit(this.placeholderString);
    } else {
      this.placeholderPreview = this.placeholderString;
    }
    this.dialogRef?.close(true);
  }

  getAreas(): string[] {
    if (this.suggestions) {
      return this.suggestions.map((s) => s.name);
    }
    return [];
  }

  getElements(): string[] {
    if (this.area) {
      const selectedArea = this.suggestions.find((s) => s.name === this.area);
      if (selectedArea) {
        return selectedArea.elements.map((f) => f.elementId);
      }
    }
    return [];
  }

  getFields(): string[] {
    if (this.area && this.element) {
      const matchingArea = this.suggestions.find((s) => s.name === this.area);
      if (matchingArea) {
        const matchingElement = matchingArea.elements.find(
          (f) => f.elementId === this.element,
        );
        if (matchingElement) {
          return matchingElement.fields.map((f) => f.name);
        }
      }
    }
    return [];
  }

  areaSelectionChanged(): void {
    const newAreaContainsElement = this.getElements().some(
      (elementId) => elementId === this.element,
    );
    if (!newAreaContainsElement) {
      this.form.patchValue(
        { element: undefined, field: undefined },
        { emitEvent: false },
      );
    }
  }

  elementSelectionChange(): void {
    const newElementContainsValue = this.getFields().some(
      (value) => value === this.field,
    );
    if (!newElementContainsValue) {
      this.form.controls['field'].patchValue(undefined, { emitEvent: false });
    }
  }

  setElementEnableState(): void {
    if (!this.area || !this.getAreas().includes(this.area)) {
      this.form.controls['element'].disable({ emitEvent: false });
    } else {
      this.form.controls['element'].enable({ emitEvent: false });
    }
  }

  setFieldEnableState(): void {
    if (!this.element || !this.getElements().includes(this.element)) {
      this.form.controls['field'].disable({ emitEvent: false });
    } else {
      this.form.controls['field'].enable({ emitEvent: false });
    }
  }

  private validator(itemlist: () => string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const item = control.value;
      if (item === null || item === undefined) {
        return null;
      }

      if (item === '') {
        return { empty: true };
      }

      if (itemlist().some((e) => e === item)) {
        return null;
      } else {
        return { invalid: true };
      }
    };
  }
}
