<hpm-header [title]="template?.name ?? ''">
      <mat-icon class="white-medium-icon linkback" routerLink="/template">list_alt</mat-icon>
  @if (templateRevisionInfos.length !== 0) {
    <cx-menu class="header-menu">
      <cx-menu-item (keydown.space)="loadOriginalTemplate()" (click)="loadOriginalTemplate()">
        <div class="menu-entry" [class.selected]="!revisionTemplate">
          <div>{{ template?.documentVersion }}</div>
          <div class="subtitle">{{ 'TEMPLATE_EDITOR.REVISION.CURRENT' | translate }}</div>
        </div>
      </cx-menu-item>
      @for (revisionInfo of templateRevisionInfos; track revisionInfo) {
        <cx-menu-item class="menu-entry" (keydown.space)="loadRevision(revisionInfo.templateId)"
                      (click)="loadRevision(revisionInfo.templateId)"
                      [class.selected]="revisionTemplate?.documentVersion === revisionInfo.revisionName">
          <div class="menu-entry" [class.selected]="revisionTemplate?.documentVersion === revisionInfo.revisionName">
            <div>{{ revisionInfo.revisionName }}</div>
            <div class="subtitle">{{ (revisionInfo.lastModified  | date: 'dd.MM.yyyy') ?? '' }}</div>
          </div>
        </cx-menu-item>
      }
    </cx-menu>
  }
</hpm-header>

@if (!revisionTemplate) {
  <cx-floating-button [text]="'TEMPLATE_EDITOR.SAVE' | translate" icon="save" (click)="saveTemplate()" />
  <cx-second-floating-button [text]="'TEMPLATE_EDITOR.DISCARD' | translate" icon="restart_alt" (click)="discardChanges()" />
}

<div class="container">
  @if (revisionTemplate) {
    <hpm-template-editor [readonly]="true" [template]="revisionTemplate"></hpm-template-editor>
  } @else if (template) {
    <hpm-template-editor #templateEditor [template]="template"></hpm-template-editor>
  }
</div>
