import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PlaceholderEditComponent } from './condition-edit/placeholder-edit/placeholder-edit.component';
import { NgControl } from '@angular/forms';

type Selection = {
  selectionStart: number;
  selectionEnd: number;
  selectedText: string;
};

@Directive({
  selector: '[hpmPlaceholderEdit]',
})
export class PlaceholderEditDirective implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  private selection: Selection | undefined = undefined;
  private overlayRef: OverlayRef | undefined;
  private componentRef: ComponentRef<PlaceholderEditComponent> | undefined;
  private firstChange = true;

  constructor(
    private elementRef: ElementRef,
    private ngControl: NgControl,
    private overlay: Overlay,
  ) {}

  @HostListener('selectionchange')
  selectionChanged(): void {
    // selectionChange is triggered on load
    if (this.firstChange) {
      this.firstChange = false;
    } else {
      this.selection = this.getSelection();
      if (this.selection && this.selection.selectedText.length > 0) {
        this.openOrUpdateMenu();
      }
    }
  }

  @HostListener('contextmenu', ['$event'])
  contextMenu(event: Event): void {
    event.preventDefault();
    this.selection = this.getSelection();
    this.openOrUpdateMenu();
  }

  @HostListener('focusout')
  focusOut(): void {
    setTimeout(() => {
      this.closeMenu();
    }, 300);
  }

  ngOnDestroy(): void {
    this.closeMenu();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  closeMenu(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }

  openOrUpdateMenu(): void {
    if (this.componentRef) {
      this.componentRef.instance.placeholderString = this.selection
        ?.selectedText
        ? this.selection.selectedText
        : '';
    } else {
      this.openMenu();
    }
  }

  private getSelection(): Selection | undefined {
    const selectionStart = this.elementRef.nativeElement.selectionStart
      ? this.elementRef.nativeElement.selectionStart
      : 0;
    const selectionEnd = this.elementRef.nativeElement.selectionEnd
      ? this.elementRef.nativeElement.selectionEnd
      : 0;
    if (this.elementRef.nativeElement.value !== undefined) {
      const selectedText = this.elementRef.nativeElement.value.slice(
        selectionStart,
        selectionEnd,
      );
      return { selectionEnd, selectionStart, selectedText };
    } else {
      return undefined;
    }
  }

  private openMenu(): void {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'center',
        },
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
    const component = new ComponentPortal(PlaceholderEditComponent);
    this.componentRef = this.overlayRef.attach(component);
    this.componentRef.instance.placeholderString = this.selection?.selectedText
      ? this.selection.selectedText
      : '';
    this.componentRef.instance.placeholderChange.subscribe((placeholder) => {
      this.replaceText(placeholder);
    });
  }

  private replaceText(placeholder: string): void {
    if (this.selection) {
      const existingText: string = this.elementRef.nativeElement.value;
      const newText =
        existingText.substring(0, this.selection?.selectionStart) +
        placeholder +
        existingText.substring(this.selection?.selectionEnd);
      if (this.ngControl?.control) {
        this.ngControl.control.patchValue(newText);
      }
    }
  }
}
