import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { TemplateHttpService } from '../../../template-overview/template-http.service';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(private templateHttpService: TemplateHttpService) {}

  validateCondition(
    control: AbstractControl,
  ): Observable<ValidationErrors | null> {
    return this.validateExpression(control, true);
  }

  validateText(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.validateExpression.bind(this)(control, false);
  }

  private validateExpression(
    control: AbstractControl,
    isCondition: boolean,
  ): Observable<ValidationErrors | null> {
    const conditionExpression = control.value;
    if (!conditionExpression) {
      return of(null);
    } else {
      return this.templateHttpService
        .isExpressionValid(conditionExpression, isCondition)
        .pipe(
          map((expressionValid) => {
            if (expressionValid) {
              return null;
            } else {
              return { invalid: true } as ValidationErrors;
            }
          }),
        );
    }
  }
}
