import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  CxDialogComponent,
  CxDialogConfig,
  CxDialogService,
} from '@bbraun/cortex/dialog';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ConditionHttpService } from '../condition-http.service';
import { ConditionSelection } from '../condition.model';
import { ConditionService } from './condition.service';

export type ConditionLine = {
  area?: string;
  elementId?: string;
  value?: string;
  indentation: number;
  operator?: Operator;
  operatorIndentation: number;
};
type Operator = 'AND' | 'OR';

@Component({
  selector: 'hpm-condition-edit',
  templateUrl: './condition-edit.component.html',
  styleUrl: './condition-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConditionEditComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();

  @ViewChild('conditionEdit') templateRef: TemplateRef<never> | undefined;
  @Input() condition = '';
  conditionPreview = '';
  @Output() conditionChange: EventEmitter<string> = new EventEmitter<string>();
  dialogRef: MatDialogRef<CxDialogComponent> | undefined;
  conditions: ConditionLine[] = [];
  suggestions: ConditionSelection[] = [];

  constructor(
    private conditionHttpService: ConditionHttpService,
    private conditionService: ConditionService,
    private dialogService: CxDialogService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getSelectOptions();
  }

  updatePreview(): void {
    this.conditionPreview = this.conditionService.convertToConditionString(
      this.conditions,
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getSelectOptions(): void {
    this.conditionHttpService
      .getSelectOptions()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((suggestions) => {
        this.suggestions = suggestions;
      });
  }

  async openDialog(): Promise<void> {
    if (!this.dialogRef) {
      this.conditionPreview = this.condition;
      this.setConditions();
      this.dialogRef = this.dialogService.openDialog(
        await this.getUpdateDialogConfig(),
      );
      this.dialogRef.updateSize('1200px', '800px');
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = undefined;
        this.conditions = [];
        this.cdr.detectChanges();
      });
    }
  }

  private async getUpdateDialogConfig(): Promise<CxDialogConfig> {
    const title = await firstValueFrom(
      this.translateService.get(
        `TEMPLATE_EDITOR.EDITOR_TABLE.CONDITION_EDIT.HEADLINE`,
      ),
    );

    return {
      title: title,
      template: this.templateRef,
    } as CxDialogConfig;
  }

  setConditions(): void {
    this.conditions = this.conditionService.parseCondition(
      this.conditionPreview,
    );
  }

  onClose(confirm: boolean): void {
    if (confirm) {
      this.condition = this.conditionPreview;
      this.conditionChange.emit(this.condition);
    } else {
      this.conditionPreview = this.condition;
    }
    this.dialogRef?.close(true);
  }

  getAreas(): string[] {
    if (this.suggestions) {
      return this.suggestions.map((s) => s.name);
    }
    return [];
  }

  getElements(condition: ConditionLine): string[] {
    if (condition.area) {
      const area = this.suggestions.find((s) => s.name === condition.area);
      if (area) {
        return area.elements.map((f) => f.elementId);
      }
    }
    return [];
  }

  getValues(condition: ConditionLine): string[] {
    if (condition.area && condition.elementId) {
      const area = this.suggestions.find((s) => s.name === condition.area);
      if (area) {
        const element = area.elements.find(
          (f) => f.elementId === condition.elementId,
        );
        if (element) {
          const valuelist: string[] = [];
          element.fields.forEach((field) =>
            field.conditions.forEach((condition) => {
              valuelist.push(`'${field.name}' ${condition}`);
            }),
          );
          return valuelist;
        }
      }
    }
    return [];
  }

  areaSelectionChanged(condition: ConditionLine): void {
    const newAreaContainsElement = this.getElements(condition).some(
      (elementId) => elementId === condition.elementId,
    );
    if (!newAreaContainsElement) {
      condition.elementId = undefined;
      condition.value = undefined;
    }
    this.elementSelectionChange(condition);
  }

  elementSelectionChange(condition: ConditionLine): void {
    const newElementContainsValue = this.getValues(condition).some(
      (value) => value === condition.value,
    );
    if (!newElementContainsValue) {
      condition.value = undefined;
    }
  }

  increaseIndent(indent: number): number {
    if (!indent) {
      return 1;
    } else {
      return indent + 1;
    }
  }

  decreaseIndent(indent: number): number {
    if (!indent || indent === 1) {
      return 0;
    } else {
      return indent - 1;
    }
  }

  addLine(operator: Operator, condition: ConditionLine, index: number): void {
    if (!condition.operator) {
      this.conditions.splice(index + 1, 0, {
        indentation: condition.indentation,
        operatorIndentation: condition.indentation,
      });
    }
    condition.operator = operator;
    this.updatePreview();
  }

  deleteCondition(index: number): void {
    if (index > 0 && index === this.conditions.length - 1) {
      this.conditions[index - 1].operator = undefined;
    }
    this.conditions.splice(index, 1);
    if (this.conditions.length === 0) {
      this.conditions = [this.conditionService.getEmptyCondition()];
    }
    this.cdr.detectChanges();
    this.updatePreview();
  }

  isElementDisabled(condition: ConditionLine): boolean {
    return !condition.area || !this.getAreas().includes(condition.area);
  }

  isValueDisabled(condition: ConditionLine): boolean {
    return (
      !condition.elementId ||
      !this.getElements(condition).includes(condition.elementId)
    );
  }
}
