@if (!editMode && icon) {
  <img *ngIf="icon" [src]="getIconPath(icon)" [alt]="getIconName(icon)">
} @else if (editMode) {

  <div class="editable-icon">
    @if (icon) {
      <img [src]="getIconPath(icon)"
           [alt]="getIconName(icon)"
           (click)="open(icon)"
           tabindex="0"
           (keydown.space)="open(icon)"
           (keydown.enter)="open(icon)"
      >
    } @else {
      <button mat-icon-button (click)="open(undefined)">
        <mat-icon class="material-symbols-outlined">add_photo_alternate</mat-icon>
      </button>
    }
  </div>
}

<ng-template #iconDialog>
  <div class="row gap">
    <div class="icon-preview empty"
      [ngClass]="selectedIcon === undefined ? 'selected' : ''"
      (click)="selectIcon(undefined)"
      tabindex="0"
      (keydown.space)="selectIcon(undefined);$event.preventDefault()"
      (keydown.enter)="closeDialog(true)"
      (keydown.escape)="closeDialog(false)"
    ><mat-icon>block</mat-icon>
    </div>
    @for (icon of allIcons; track icon) {
      <img
        class="icon-preview"
        [ngClass]="isIconSelected(icon) ? 'selected' : ''"
        [src]="getIconPath(icon)"
        [alt]="getIconName(icon)"
        (click)="selectIcon(icon)"
        tabindex="0"
        (keydown.space)="selectIcon(icon);$event.preventDefault()"
        (keydown.enter)="closeDialog(true)"
        (keydown.escape)="closeDialog(false)"
      >
    }
  </div>
</ng-template>
