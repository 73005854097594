import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Template } from '../../model/template.model';
import { getEmptySection, Section } from '../../model/section.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Subject, takeUntil } from 'rxjs';
import { WordTemplateHttpService } from '../../template-overview/word-template-http.service';

@Component({
  selector: 'hpm-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrl: './template-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  @Input() template!: Template;
  @Input() readonly = false;

  editIndex: number | undefined;
  wordTemplates: string[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private wordTemplateService: WordTemplateHttpService,
  ) {}

  ngOnInit(): void {
    this.loadWordTemplates();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadWordTemplates(): void {
    this.wordTemplateService
      .getAllWordTemplates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((wordTemplates) => {
        this.wordTemplates = wordTemplates;
        this.cdr.detectChanges();
      });
  }

  reset(): void {
    this.editIndex = undefined;
  }

  toggleEdit(index: number): void {
    this.editIndex = this.editIndex === index ? undefined : index;
  }

  duplicateSection(index: number): void {
    if (this.template.sections) {
      this.template.sections.splice(index + 1, 0, {
        ...this.template.sections[index],
      });
    }
    this.editIndex = index + 1;
  }

  deleteSection(index: number): void {
    if (this.template.sections) {
      if (index === this.editIndex) {
        this.editIndex = undefined;
      }
      if (this.editIndex !== undefined && index < this.editIndex) {
        this.editIndex--;
      }

      this.template.sections.splice(index, 1);
    }
  }

  addSection(index: number): void {
    const emptySection: Section = getEmptySection();
    if (!this.template.sections?.length) {
      this.template.sections = [];
    }
    this.template.sections.splice(index + 1, 0, emptySection);
    this.editIndex = index + 1;
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (this.template.sections) {
      this.moveEditIndex(event.previousIndex, event.currentIndex);
      const movedElement = this.template.sections.splice(
        event.previousIndex,
        1,
      )[0];
      this.template.sections.splice(event.currentIndex, 0, movedElement);
    }
  }

  private moveEditIndex(previousIndex: number, currentIndex: number): void {
    if (this.editIndex !== undefined) {
      if (currentIndex <= this.editIndex && previousIndex > this.editIndex) {
        this.editIndex++;
      } else if (
        currentIndex >= this.editIndex &&
        previousIndex < this.editIndex
      ) {
        this.editIndex--;
      } else if (previousIndex === this.editIndex) {
        this.editIndex = currentIndex;
      }
    }
  }

  getHeaderFontSize(headingLevel: number): number {
    switch (headingLevel) {
      case 2:
      case 3:
        return 12;
      case 4:
        return 11;
      case 1:
      default:
        return 14;
    }
  }
}
