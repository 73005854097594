import { Injectable } from '@angular/core';
import { ConditionLine } from './condition-edit.component';

@Injectable({
  providedIn: 'root',
})
export class ConditionService {
  private readonly EMPTY_CONDITION: ConditionLine = {
    indentation: 0,
    operatorIndentation: 0,
  };
  private readonly conditionRegex = /\s*'([^']*)'\.'([^']*)'\.([^)]*)\s*/g;
  private readonly openingBraceRegex = /^\s*\(\s*\(/;
  private readonly closingBraceRegex = /\)\s*\)\s*$/;

  getEmptyCondition(): ConditionLine {
    return { ...this.EMPTY_CONDITION };
  }

  parseCondition(conditionString: string): ConditionLine[] {
    if (!conditionString || conditionString === '') {
      return [this.getEmptyCondition()];
    } else {
      return this.parseComplexString(conditionString);
    }
  }

  private parseComplexString(conditionString: string): ConditionLine[] {
    const result: ConditionLine[] = [];
    let condition: ConditionLine = this.getEmptyCondition();
    let indentionLevel = 0;

    conditionString.split(/\s(or|and)\s/).forEach((part) => {
      if (this.openingBraceRegex.exec(part)) {
        indentionLevel++;
      }

      if (part === 'or') {
        condition.operator = 'OR';
        condition.operatorIndentation = indentionLevel;
        result.push(condition);
      } else if (part === 'and') {
        condition.operator = 'AND';
        condition.operatorIndentation = indentionLevel;
        result.push(condition);
      } else {
        let match;
        while ((match = this.conditionRegex.exec(part)) !== null) {
          condition = {
            area: this.getElement(match[1]),
            elementId: this.getElement(match[2]),
            value: this.getElement(match[3]),
            indentation: indentionLevel,
            operatorIndentation: indentionLevel,
          };
        }
      }

      if (this.closingBraceRegex.exec(part)) {
        indentionLevel = indentionLevel === 0 ? 0 : indentionLevel - 1;
      }
    });
    result.push(condition);
    return result;
  }

  private getElement(element: string): string | undefined {
    if (!element || element.trim() === '') {
      return undefined;
    } else {
      return element.trim();
    }
  }

  convertToConditionString(conditions: ConditionLine[]): string {
    if (!conditions || conditions.length === 0) {
      return '';
    } else if (conditions.length === 1) {
      return this.getConditionString(conditions[0]);
    } else {
      return this.conditionsToString(conditions);
    }
  }

  private conditionsToString(conditions: ConditionLine[]): string {
    let finalString = '';
    let beforeIndent = 0;
    for (const condition of conditions) {
      let conditionString = '';
      conditionString = this.addOpeningIndentionBraces(
        conditionString,
        condition,
        beforeIndent,
      );
      conditionString = this.elementExpression(conditionString, condition);
      conditionString = this.closingIndentionBraces(conditionString, condition);
      conditionString = this.operator(conditionString, condition);
      finalString += conditionString;
      beforeIndent = condition.operatorIndentation;
    }
    finalString = this.closeAllOpenBraces(conditions, finalString);
    return finalString.trim();
  }

  private addOpeningIndentionBraces(
    conditionString: string,
    condition: ConditionLine,
    beforeIndent: number,
  ): string {
    if (condition.indentation > beforeIndent) {
      conditionString += ' (';
    }
    return conditionString;
  }

  private elementExpression(
    conditionString: string,
    condition: ConditionLine,
  ): string {
    conditionString += ' ( ' + this.getConditionString(condition) + ' ) ';
    return conditionString;
  }

  private closingIndentionBraces(
    conditionString: string,
    condition: ConditionLine,
  ): string {
    if (condition.operatorIndentation < condition.indentation) {
      conditionString += ') ';
    }
    return conditionString;
  }

  private operator(conditionString: string, condition: ConditionLine): string {
    if (condition.operator) {
      conditionString += condition.operator.toLowerCase();
    }
    return conditionString;
  }

  private closeAllOpenBraces(
    conditions: ConditionLine[],
    finalString: string,
  ): string {
    for (let i = 0; i < conditions[conditions.length - 1].indentation; i++) {
      finalString += ') ';
    }
    return finalString;
  }

  private getConditionString(condition: ConditionLine): string {
    const area = condition.area ? condition.area : '';
    const element = condition.elementId ? condition.elementId : '';
    const value = condition.value ? condition.value : '';
    return `'${area}'.'${element}'.${value}`;
  }
}
