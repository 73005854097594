import { Injectable } from '@angular/core';
import { PlaceholderObject } from './placeholder-edit.component';

@Injectable({
  providedIn: 'root',
})
export class PlaceholderService {
  private readonly placeholderRegex =
    /{?\s*'([^']*)'\.'([^']*)'\.'([^']*)'\s*}?/;
  private readonly prefixRegex = RegExp(/format\(\s*['"]([^'"]*)['"]/); // matches format('prefix'
  private readonly suffixRegex = RegExp(/['"]([^'"]*)['"]\s*\)/); // matches 'suffix')

  parsePlaceholder(placeholderString: string): PlaceholderObject {
    if (!placeholderString || placeholderString === '') {
      return {};
    } else {
      const formatValues: PlaceholderObject =
        this.extractFormat(placeholderString);
      const placeholderValues = this.extractPlaceholder(placeholderString);

      return {
        ...formatValues,
        area: placeholderValues.area,
        field: placeholderValues.field,
        element: placeholderValues.element,
      };
    }
  }

  private extractPlaceholder(placeholderString: string): PlaceholderObject {
    const match = this.placeholderRegex.exec(placeholderString);
    if (match !== null) {
      let val = this.getElement(match[3]);
      if (val !== undefined) {
        val = val.replaceAll(`'`, '');
      }
      return {
        area: this.getElement(match[1]),
        element: this.getElement(match[2]),
        field: val,
      };
    } else {
      return {};
    }
  }

  convertToPlaceholderString(
    placeholder: PlaceholderObject | undefined,
  ): string {
    if (!placeholder?.area) {
      return '';
    }
    const area = placeholder.area ? placeholder.area : '';
    const element = placeholder.element ? placeholder.element : '';
    const field = placeholder.field ? placeholder.field : '';
    const questionId = `'${area}'.'${element}'.'${field}'`;
    if (placeholder.hasFormat) {
      return `{ format('${placeholder.prefix}', ${questionId}, '${placeholder.suffix}')}`;
    } else {
      return `{${questionId}}`;
    }
  }

  private getElement(element: string): string | undefined {
    if (!element || element.trim() === '') {
      return undefined;
    } else {
      return element.trim();
    }
  }

  private extractFormat(placeholderString: string): PlaceholderObject {
    const matchPrefix = this.prefixRegex.exec(placeholderString);
    if (matchPrefix) {
      const matchSuffix = this.suffixRegex.exec(placeholderString);
      return {
        hasFormat: true,
        suffix: matchSuffix?.[1] ?? '',
        prefix: matchPrefix?.[1] ?? '',
      };
    }
    return {};
  }
}
