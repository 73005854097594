import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class IconHttpService {
  private readonly baseUrl = `${environment.baseUrl}/icon`;

  constructor(private httpClient: HttpClient) {}

  getIconUrl(iconPath: string): string {
    return this.baseUrl + '/' + iconPath;
  }

  loadAllIcons(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.baseUrl}/icons`);
  }

  saveIcon(iconName: string, file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<void>(`${this.baseUrl}/${iconName}`, formData);
  }

  deleteIcon(icon: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${icon}`);
  }
}
