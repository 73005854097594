import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class WordTemplateHttpService {
  private readonly url = `${environment.baseUrl}/word-template`;
  constructor(private httpClient: HttpClient) {}

  getAllWordTemplates(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.url}/`);
  }

  saveWordTemplate(wordTemplate: string, file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<void>(`${this.url}/${wordTemplate}`, formData);
  }

  deleteWordTemplate(wordTemplate: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${wordTemplate}`);
  }
}
