import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  CxDialogComponent,
  CxDialogConfig,
  CxDialogService,
} from '@bbraun/cortex/dialog';
import { forkJoin, map, Observable, Subject } from 'rxjs';
import { TemplateHttpService } from '../../template-http.service';
import { Template } from '../../../model/template.model';
import { CxFileProgress } from '@bbraun/cortex/file-uploader/file-uploader.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hpm-template-upload-dialog',
  templateUrl: './template-upload-dialog.component.html',
  styleUrl: './template-upload-dialog.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateUploadDialogComponent implements OnDestroy {
  @Input() template!: Template;
  @Input() disabled = false;
  @Output() templateChanged: EventEmitter<void> = new EventEmitter<void>();
  private onDestroy$: Subject<void> = new Subject();
  dialogRef: MatDialogRef<CxDialogComponent> | undefined;
  @ViewChild('uploadDialog') templateRef: TemplateRef<never> | undefined;
  files: Array<CxFileProgress> = [];

  constructor(
    private dialogService: CxDialogService,
    private translateService: TranslateService,
    private templateHttpService: TemplateHttpService,
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  openUploadDialog(): void {
    if (!this.dialogRef) {
      this.getDialogConfig().subscribe((config) => {
        this.dialogRef = this.dialogService.openDialog(config);
        this.dialogRef.disableClose = false;

        this.dialogRef.afterClosed().subscribe((success) => {
          if (success && this.files.length === 1) {
            this.uploadFile();
          }
          this.dialogRef = undefined;
        });
      });
    }
  }

  private getDialogConfig(): Observable<CxDialogConfig> {
    return forkJoin({
      title: this.translateService.get(
        'TEMPLATE_OVERVIEW.TABLE.UPLOAD.DIALOG_HEADLINE',
      ),
      cancel: this.translateService.get(
        'TEMPLATE_OVERVIEW.TABLE.UPLOAD.DIALOG_CANCEL',
      ),
      confirm: this.translateService.get(
        'TEMPLATE_OVERVIEW.TABLE.UPLOAD.DIALOG_CONFIRM',
      ),
    }).pipe(
      map(({ title, cancel, confirm }) => {
        return {
          title: title,
          confirmButtons: [{ text: confirm, value: true }],
          cancelButtons: [{ text: cancel, value: false }],
          template: this.templateRef,
        } as CxDialogConfig;
      }),
    );
  }

  private uploadFile(): void {
    this.templateHttpService
      .override(this.template.id, this.files[0].file)
      .pipe()
      .subscribe(() => {
        this.templateChanged.emit();
      });
  }

  fileSelected(selectedFiles: File[]): void {
    this.files = selectedFiles.map((file) => {
      return { file } as CxFileProgress;
    });
  }

  fileRemoved(removedFile: File): void {
    const removeIndex = this.files.findIndex(
      (files) => files.file.name === removedFile.name,
    );
    this.files.splice(removeIndex, 1);
  }
}
