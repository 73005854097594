const isNil = (val) => {
    return typeof val === 'undefined' || val === null;
};

const camelToDashed = (str) => {
    return str.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);
};
const cleanObject = (obj) => {
    const cleanObj = {};
    Object.keys(obj).forEach((prop) => {
        if (obj[prop]) {
            cleanObj[prop] = obj[prop];
        }
    });
    return cleanObj;
};
const toStyleString = (obj) => {
    const styles = cleanObject(obj);
    return Object.entries(styles).map(([k, v]) => `${camelToDashed(k)}:${v}`).join(';');
};

class NgxEditorError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
    }
}

const uniq = () => {
    const timeStamp = Date.now().toString(36);
    const random = Math.random().toString(36).substring(2, 7);
    return `${timeStamp}${random}`;
};

const clamp = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
};

/**
 * Generated bundle index. Do not edit.
 */

export { NgxEditorError, clamp, isNil, toStyleString, uniq };

